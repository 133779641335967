<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row justify="end">
          <v-btn
            class="ma-2 btn-back"
            color="btn-back light-1"
            dark
            @click="backToSale()"
          >
            <v-icon dark left> mdi-arrow-left </v-icon>Back
          </v-btn>
          <v-col><h2>ສະຫຼຸບການຊື້ນ້ຳຂອງລູກຄ້າ</h2></v-col>
          <v-col class="al-end">
            <v-btn
              color="primary"
              @click="exportData()"
              :loading="exportLoading"
              :disabled="exportLoading"
              >Export</v-btn
            ></v-col
          >
        </v-row>
        <v-form ref="form" lazy-validation>
          <v-row justify="end">
            <v-col cols="4">
              <v-menu
                v-model="start_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="start_date"
                    label="ເລີ່ມວັນທີ"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :rules="ruleStartDate"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start_date"
                  @input="fetchData()"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu
                v-model="end_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_date"
                    label="ຫາວັນທີ"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    dense
                    :rules="ruleEndDate"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_date"
                  @input="fetchData()"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="d-flex" cols="4">
              <v-select
                v-model="selectedPayment"
                :items="payment_Types"
                label="ປະເພດການຊຳລະ"
                item-text="name"
                item-value="value"
                dense
                outlined
                @input="fetchData()"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
        <!-- <hr /> -->
        <v-row class="mb-1">
          <v-col>
            <h3>ລວມບິນ: {{ Intl.NumberFormat().format(totalBill) }}</h3>
          </v-col>
          <v-col>
            <h4>ລວມຕຸກນ້ຳ: {{ Intl.NumberFormat().format(sumTotalBottle) }}</h4>
          </v-col>

          <v-col>
            <h4>
              ລວມເງິນທັງໝົດ: {{ Intl.NumberFormat().format(sumSubTotal) }}
            </h4>
          </v-col>
          <v-col>
            <h4 class="primary--text">
              ລວມເງິນມັດຈຳ:
              {{ Intl.NumberFormat().format(sumTotalPriceFreeBottle) }}
            </h4>
          </v-col>
          <!--
          <v-col>
            <h4 class="success--text">
              ທະນາຄານ: {{ Intl.NumberFormat().format(sumTotalBank) }}
            </h4>
          </v-col>
          <v-col>
            <h4 class="info--text">
              ສະໜັບສະໜຸນ: {{ Intl.NumberFormat().format(sumTotalSupport) }}
            </h4>
          </v-col>
          <v-col>
            <h4 class="error--text">
              ຕິດໜີ້: {{ Intl.NumberFormat().format(sumTotalDebt) }}
            </h4>
          </v-col>
-->
        </v-row>
        <!-- <hr /> -->
        <v-data-table
          :headers="headers"
          :items="customerInvoices"
          :disable-pagination="true"
          hide-default-footer
          class="elevation-1"
          :loading="TableLoading"
          :disabled="TableLoading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item.bonding_fees="{ item }">
            {{ Intl.NumberFormat().format(item.bonding_fees) }}
          </template>
          <template v-slot:item.package_price="{ item }">
            {{ Intl.NumberFormat().format(item.package_price) }}
          </template>

          <template v-slot:item.subTotal="{ item }">
            {{ Intl.NumberFormat().format(item.subTotal) }}
          </template>
          <template v-slot:item.totalPriceFreeBottle="{ item }">
            {{ Intl.NumberFormat().format(item.totalPriceFreeBottle) }}
          </template>
          <template v-slot:item.invoice_status="{ item }">
            <v-chip color="success">{{ item.invoice_status }}</v-chip>
          </template>
          <template v-slot:item.payment_type="{ item }">
            <div :class="getPaymentTypeColor(item.payment_type)">
              {{ paymentType(item.payment_type) }}
            </div>
          </template>
          <!--Action -->
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="viewInvoice(item)"
              >mdi-eye</v-icon
            >
            <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
          </template>
        </v-data-table>
        <br />
        <template>
          <Pagination
            v-if="pagination.last_page > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchData()"
          ></Pagination>
        </template>
      </v-card-text>
    </v-card>

<!--    <ModalView v-if="invoiceDetailItem.length">-->
<!--      <template @close="close">-->
<!--        <v-card mdi-map-marker-circle :loading="loading" :disabled="loading">-->
<!--          <v-card-title>-->
<!--            <span>ລາຍການຕຸກນ້ຳໃນບິນ</span>-->
<!--          </v-card-title>-->
<!--          <v-card-text>-->
<!--            <v-container>-->
<!--              <v-data-table-->
<!--                :headers="invoiceHeader"-->
<!--                :items="invoiceDetailItem"-->
<!--                hide-default-footer-->
<!--              >-->
<!--                <template v-slot:item.price="{ item }">-->
<!--                  <div :class="getFreeStyle(item.sale_type)">-->
<!--                    {{ saleType(item.sale_type, item.price) }}-->
<!--                  </div>-->
<!--                </template> </v-data-table-->
<!--              ><br />-->
<!--              <v-row class="justify-end">-->
<!--                <v-col cols="9" class="d-flex justify-end">-->
<!--                  <h2 class="invoice justify-end">ຈຳນວນຕຸກນ້ຳ:</h2>-->
<!--                </v-col>-->
<!--                <v-col class="d-flex justify-end">-->
<!--                  <h2 cols="3" class="invoice justify-end">-->
<!--                    {{ invoiceDetailItem.length }}-->
<!--                  </h2>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-container>-->
<!--            <br />-->
<!--            <v-card-actions>-->
<!--              <v-spacer></v-spacer>-->
<!--              <v-btn color="error" class="mr-2" depressed @click="closeModal()">-->
<!--                Close-->
<!--              </v-btn>-->
<!--            </v-card-actions>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </template>-->
<!--    </ModalView>-->


    <ModalView v-if="invoiceDetail">
      <template @close="close">
        <v-card mdi-map-marker-circle :loading="loading" :disabled="loading">
          <v-card-title>
            <h4>ລາຍລະອຽດໃບບິນ</h4>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <div class="invoice">
                    ເລກບິນ: {{ invoiceDetail.invoice_number }}
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="invoice">
                    ວັນທີຂາຍ: {{ invoiceDetail.createdDate }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="invoice">
                    ປະເພດການຊຳລະ:
                    <span
                        :class="getPaymentTypeColor(invoiceDetail.payment_type)"
                    >{{ paymentType(invoiceDetail.payment_type) }}</span
                    >
                  </div>
                </v-col>
                <v-col cols="6">
                  <div class="invoice">
                    ປະເພດຜູ້ຂາຍ:
                    <span class="primary-color">{{
                        saleByType(invoiceDetail.create_by_type)
                      }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6" v-if="createdByDetail.name">
                  <div class="invoice">
                    ຜູ້ຂາຍ:
                    <span class="primary-color">{{
                        createdByDetail.name
                      }}</span>
                  </div>
                  <span
                      v-for="(item, index) in invoiceDetail.createByMembers"
                      :key="index"
                  >
                    {{ item.name }}
                  </span>
                </v-col>
                <v-col cols="6" v-if="customerDetail.name">
                  <div class="invoice">
                    ຂາຍໃຫ້: {{ customerDetail.name }}
                    {{ customerDetail.surname }}
                    {{ customerDetail.village_name }}, ໂທ:
                    {{ customerDetail.phone }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <div class="invoice">
                    ສະຖານະ
                    <v-chip color="success" label>{{
                        invoiceDetail.invoice_status
                      }}</v-chip>
                  </div>
                </v-col>
              </v-row>
              <v-data-table
                  :headers="invoiceHeader"
                  :items="invoiceDetailItem"
                  :item-per-page="15"
              >
                <template v-slot:item.price="{ item }">
                  <div :class="getFreeStyle(item.sale_type)">
                    {{ saleType(item.sale_type, item.price) }}
                  </div>
                </template>
                <template slot="item.index" scope="props">
                  <div>{{ props.index + 1 }}</div>
                </template>
              </v-data-table>
              <v-row class="justify-end mt-0">
                <v-col cols="9" class="d-flex justify-end">
                  <h2 class="invoice justify-end">ຈຳນວນຕຸກນ້ຳ:</h2>
                </v-col>
                <v-col class="d-flex justify-end">
                  <h2 cols="3" class="invoice justify-end">
                    {{ invoiceDetail.totalBottle }}
                  </h2>
                </v-col>
              </v-row>
              <v-row class="justify-end mt-0">
                <v-col cols="9" class="d-flex justify-end">
                  <h2 class="invoice justify-end">ລວມຄ່ານ້ຳທັງໝົດ:</h2>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                  <h2 class="invoice justify-end">
                    {{ Number(invoiceDetail.subTotal).toLocaleString() }} LAK
                  </h2>
                </v-col>
              </v-row>
              <v-row class="justify-end mt-0">
                <v-col cols="9" class="d-flex justify-end">
                  <h2 class="invoice justify-end">ລວມຄ່າມັດຈຳ:</h2>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                  <h2 class="invoice justify-end">
                    {{ Number(invoiceDetail.bonding_fees).toLocaleString() }}
                    LAK
                  </h2>
                </v-col>
              </v-row>
              <v-row class="justify-end mt-0">
                <v-col cols="9" class="d-flex justify-end">
                  <h3>ທັງໝົດທີ່ຕ້ອງຈ່າຍ:</h3>
                </v-col>
                <v-col cols="3" class="d-flex justify-end">
                  <h3>
                    {{
                      totalPay(
                          invoiceDetail.subTotal,
                          invoiceDetail.bonding_fees
                      )
                    }}
                    LAK
                  </h3>
                </v-col>
              </v-row>
            </v-container>
            <br />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" class="mr-2" depressed @click="closeModal()">
                Close
              </v-btn>
              <v-btn
                  color="primary"
                  depressed
                  @click="confirmPaymentStatus('cash')"
              >
                ຊຳລະເງິນສົດ
              </v-btn>
              <v-btn
                  color="success"
                  class="mr-2"
                  depressed
                  @click="confirmPaymentStatus('bank')"
              >
                ຊຳລະທະນາຄານ
              </v-btn>
              <v-btn
                  color="info"
                  class="mr-2"
                  depressed
                  @click="confirmPaymentStatus('support')"
              >
                ສະໝັບສະໜູນ
              </v-btn>
              <v-btn
                  color="warning"
                  class="mr-2"
                  depressed
                  @click="confirmPaymentStatus('debt')"
              >
                ຕິດໜີ້
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </template>
    </ModalView>

  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      id: this.$route.params.id,
      customerInvoices: [],

      // start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      // end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      start_date: null,
      end_date: null,
      start_menu: false,
      end_menu: false,

      loading: false,
      bankLoading: false,
      TableLoading: false,
      exportLoading: false,
      invoiceId: "",
      finances: [],
      invoiceDetailItem: [],
      invoiceDetail: {},
      customerDetail: {},
      createdByDetail: {},
      search: "",

      //Pagination
      offset: 12,
      pagination: {},
      per_page: 70,
      totalBill: 0,
      sumSubTotal: 0,
      sumTotalAmountFreeBottle: 0,
      sumTotalBottle: 0,
      sumTotalCost: 0,
      sumTotalPriceFreeBottle: 0,
      sumTotalProfit: 0,
      //Payment Type
      selectedPayment: "all",
      payment_Types: [
        {
          value: "all",
          name: "ທັງໝົດ",
        },
        {
          value: "cash",
          name: "ເງິນສົດ",
        },
        {
          value: "bank",
          name: "ທະນາຄານ",
        },
        {
          value: "support",
          name: "ສະໜັບສະໜູນ",
        },
        {
          value: "debt",
          name: "ຕິດໜີ້",
        },
      ],
      headers: [
        {
          text: "ເລກບິນ",
          align: "start",
          sortable: false,
          value: "invoice_number",
        },
        { text: "ຈຳນວນຕຸກນ້ຳ", value: "totalBottle", align: "center" },
        {
          text: "ຕຸກມັດຈຳ",
          value: "amountBottleBonding",
          align: "center",
        },
        { text: "ຄ່າມັດຈຳ", value: "bonding_fees", align: "center" },
        // {
        //   text: "ຄ່າມັດຈຳທັງໝົດ",
        //   value: "totalPriceFreeBottle",
        //   align: "center",
        // },
        {
          text: "ລາຄາຕໍ່ຕຸກ",
          value: "package_price",
          align: "center",
        },
        { text: "ລວມຄ່ານ້ຳ", value: "subTotal", align: "center" },
        {
          text: "ປະເພດຊຳລະ",
          value: "payment_type",
          align: "center",
        },
        { text: "ວັນທີຂາຍ", value: "createdDate" },
        { text: "ວັນທີຊຳລະ", value: "paymentDate" },
        { text: "View", value: "actions", sortable: false, align: "center" },
      ],
      editedIndex: -1,

      invoiceHeader: [
        {
          text: "ລະຫັດຕຸກນ້ຳ",
          align: "start",
          sortable: false,
          value: "barcode",
        },
        { text: "ຊື່ລາຍການ", value: "product_name" },
        { text: "ລາຄາ", value: "price" },
      ],
      ruleStartDate: [(v) => !!v || "Start Date is required"],
      ruleEndDate: [(v) => !!v || "End Date is required"],
    };
  },
  methods: {
    backToSale() {
      this.$router.go(-1);
    },
    fetchData() {
      this.TableLoading = true;
      this.$axios
        .post("report/invoice/customer/" + this.id, {
          startDate: this.start_date,
          endDate: this.end_date,
          page: this.pagination.current_page,
          per_page: this.per_page,
          payment_type: this.selectedPayment,
        })
        .then((res) => {
          setTimeout(() => {
            this.customerInvoices = res.data.invoices.data;
            this.pagination = res.data.invoices;
            this.totalBill = res.data.invoices.total;
            this.sumSubTotal = res.data.sumSubTotal;
            this.sumTotalAmountFreeBottle = res.data.sumTotalAmountFreeBottle;
            this.sumTotalBottle = res.data.sumTotalBottle;
            this.sumTotalCost = res.data.sumTotalCost;
            this.sumTotalPriceFreeBottle = res.data.sumTotalPriceFreeBottle;
            this.sumTotalProfit = res.data.sumTotalProfit;
            this.start_menu = false;
            this.end_menu = false;
            this.TableLoading = false;
          }, 100);
        })
        .catch(() => {
          this.TableLoading = false;
          this.end_menu = false;
          this.start_menu = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    viewInvoice(item) {
      this.loading = true;
      this.invoiceId = item.id;
      this.invoiceDetail = item;
      this.$store.commit("modalView_State", true);
      this.$axios
        .get("report/invoice/detail/customer/" + this.invoiceId)
        .then((res) => {
          setTimeout(() => {
            this.invoiceDetailItem = res.data.invoiceDetails;
            this.loading = false;
          }, 100);
        })
        .catch(() => {
          this.loading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
    closeModal() {
      this.$store.commit("modalView_State", false);
    },
    paymentType(value) {
      if (value == "debt") return "ຕິດໜີ້";
      else if (value == "cash") return "ເງິນສົດ";
      else if (value == "bank") return "ທະນາຄານ";
      else return;
    },
    getPaymentTypeColor(value) {
      if (value == "bank") return "success--text";
      else if (value == "cash") return "primary--text";
      else if (value == "debt") return "error--text";
    },
    saleType(sale, price) {
      if (sale == "sale") return Number(price).toLocaleString();
      else if (sale == "free") return "Free";
    },
    getFreeStyle(sale) {
      if (sale == "free") return "primary-color";
    },
    totalPay(b, total) {
      return Number(b + total).toLocaleString();
    },
    saleByType(type) {
      if (type == "driver") {
        return "ລົດຂົນສົ່ງ";
      } else if (type == "branch") {
        return "ສາຂາ";
      }
    },

    //Confirm Payment type
    confirmPaymentStatus(status) {
      this.loading = true;
      this.$axios
          .get("payment/customer/invoice/" + this.invoiceId + "/" + status)
          .then((res) => {
            if (res.data.success == true) {
              setTimeout(() => {
                this.loading = false;
                this.$store.commit("Toast_State", this.toast);
              }, 100);
            }
            this.closeModal();
            this.fetchData();
          })
          .catch((error) => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
            this.closeModal();
            this.fetchData();
            if (error.response.status == 422) {
              this.$store.commit("Toast_State", this.toast_error);
            } else {
              this.$store.commit("Toast_State", this.toast_error);
            }
          });
    },

    //Export excel
    exportData() {
      if (this.$refs.form.validate() == true) {
        this.exportLoading = true;
        this.$axios
          .post(
            "export/invoices/debt/by/customer",
            {
              customer_id: this.id,
              startDate: this.start_date,
              endDate: this.end_date,
              payment_type: this.selectedPayment,
            },
            { responseType: "blob" }
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                "download",
                "ລາຍການຂາຍ " +
                  "ປະຈຳວັນທີ " +
                  this.start_date +
                  " ຫາ " +
                  this.end_date +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.exportLoading = false;
            }, 100);
          })
          .catch(() => {
            this.exportLoading = false;
            this.$store.commit("Toast_State", this.toast_error);
          });
      }
    },
  },
  created() {
    // this.viewInvoice();
    this.fetchData();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}
.invoice {
  font-size: 16px;
  color: #000000;
}
.primary-color {
  color: #00c1d2;
}
.btn-back {
  background: #00c1d2 !important;
}
</style>
